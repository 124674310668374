import React from 'react';
import { Heading, Text, Flex } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children) => <Text my={4}>{children}</Text>,
  },
};

function MagicResearchReferences({ data }) {
  const body = data.pages.nodes[0].body;

  const richTextBody = body ? renderRichText(body, options) : '';
  return (
    <Flex flexDir="column" mt={12}>
      <Heading as="h1">1-2-3 Magic research references</Heading>
      <Text mt={4}>{richTextBody}</Text>
    </Flex>
  );
}

export default MagicResearchReferences;

export const query = graphql`
  query {
    pages: allContentfulReferencePage {
      nodes {
        body {
          raw
        }
        title
      }
    }
  }
`;
